import { Link } from "gatsby";
import React from "react";
import { useState } from "react";
import HeaderSpacer from "../../components/Header/HeaderSpacer";
import ChevronRightIcon from "../../icons/ChevronRight";
import CloseIcon from "../../icons/Close";
import { useGuideData } from "./hooks";

export default function GuideNav({
  currentSlug,
  currentTitle,
  currentCategory,
}: {
  currentSlug: string;
  currentTitle: string;
  currentCategory: string;
}) {
  const [open, setOpen] = useState(false);
  const guides = useGuideData();

  const categoryMap = guides.reduce<{
    [category: string]: { items: NavItem[] };
  }>((a, { category, order, ...props }) => {
    if (a[category]) {
      a[category].items.push(props);
    } else {
      a[category] = { items: [props] };
    }
    return a;
  }, {});

  const openMenu = () => {
    setOpen(true);
  };

  const closeMenu = () => {
    setOpen(false);
  };

  const menuItems = Object.keys(categoryMap).map((category) => (
    <NavMenuItem
      category={category}
      key={category}
      items={categoryMap[category].items}
      currentSlug={currentSlug}
    />
  ));

  return (
    <>
      <div className="w-full py-2 border-b border-t bg-gray-dark border-gray lg:border-none lg:py-0 lg:h-0 lg:w-80 lg:flex-shrink-0">
        <div className="px-8 md:px-12 lg:hidden">
          <button
            onClick={openMenu}
            className="flex items-center overflow-hidden w-full text-left"
          >
            <span className="flex-shrink-0 mr-4">
              <span className="w-5 h-0.5 bg-white block" />
              <span className="w-5 h-0.5 my-1 bg-white block" />
              <span className="w-5 h-0.5 bg-white block" />
            </span>
            <span className="flex-shrink-0">{currentCategory}</span>
            <span className="flex mx-4 flex-shrink-0 opacity-60">
              <ChevronRightIcon />
            </span>
            <span className="truncate block flex-grow">{currentTitle}</span>
          </button>
        </div>
      </div>
      <nav
        className={`fixed top-0 bottom-0 text-lg overflow-auto border-gray border-r w-80 py-6 pr-6 hidden lg:block`}
      >
        <div>
          <HeaderSpacer />
          <ul>{menuItems}</ul>
        </div>
      </nav>
      {open && (
        <nav
          className={`bg-black z-20 fixed inset-0 text-lg overflow-auto border-gray`}
        >
          <div className="flex items-center justify-between py-4 mb-4 border-b border-gray px-8">
            <span>Menu</span>
            <button className="flex -mr-1" onClick={closeMenu}>
              <CloseIcon />
            </button>
          </div>
          <ul className="px-8">{menuItems}</ul>
        </nav>
      )}
    </>
  );
}

function NavMenuItem({
  category,
  items,
  currentSlug,
}: {
  category: string;
  items: NavItem[];
  currentSlug: string;
}) {
  return (
    <li className="mb-4">
      <h6 className="font-secondary uppercase text-xs opacity-50 tracking-widest">
        {category}
      </h6>
      <ul className="pl-4 pt-2">
        {items.map(({ slug, title }) => (
          <li key={slug} className="mb-2 last:mb-0">
            <Link
              to={slug}
              className={`transition-colors ${
                slug === currentSlug
                  ? "text-primary font-medium"
                  : "text-white opacity-80 hover:opacity-100"
              }`}
            >
              {title}
            </Link>
          </li>
        ))}
      </ul>
    </li>
  );
}

interface NavItem {
  slug: string;
  title: string;
}
