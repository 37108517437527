import React from "react";
import LinkButton from "../../components/LinkButton";
import ChevronLeftIcon from "../../icons/ChevronLeft";
import ChevronRightIcon from "../../icons/ChevronRight";

export default function GuideFooter({
  nextGuide,
  previousGuide,
}: {
  nextGuide: GuideItem | undefined;
  previousGuide: GuideItem | undefined;
}) {
  return (
    <div className="md:flex items-center justify-between mt-12 mb-6">
      <div className="hidden md:block">
        {previousGuide && (
          <LinkButton
            href={previousGuide.slug}
            variant="gray"
            className="w-full md:w-auto"
          >
            <ChevronLeftIcon size={14} />
            <span className="ml-5">
              <span className="block leading-1">Previous guide</span>
              <span className="block leading-1 text-xs opacity-60">
                {previousGuide.title}
              </span>
            </span>
          </LinkButton>
        )}
      </div>
      <div>
        {nextGuide && (
          <LinkButton
            href={nextGuide.slug}
            variant="gray"
            className="w-full md:w-auto"
          >
            <span className="mr-5">
              <span className="block leading-1">Next guide</span>
              <span className="block leading-1 text-xs opacity-60">
                {nextGuide.title}
              </span>
            </span>
            <ChevronRightIcon size={14} />
          </LinkButton>
        )}
      </div>
    </div>
  );
}

interface GuideItem {
  slug: string;
  title: string;
}
