import React from "react";
import { graphql } from "gatsby";
import PageHead from "../../components/PageHead";
import Header from "../../components/Header";
import GuideNav from "./GuideNav";
import HeaderSpacer from "../../components/Header/HeaderSpacer";
import GuideFooter from "./GuideFooter";
import { useGuideData } from "./hooks";

export default function GuideTemplate({ data }) {
  const {
    markdownRemark: { frontmatter, html },
  } = data;
  const guides = useGuideData();

  const currentGuideIndex = guides.findIndex(
    (guide) => guide.slug === frontmatter.slug
  );
  const previousGuide = guides[currentGuideIndex - 1];
  const nextGuide = guides[currentGuideIndex + 1];

  return (
    <>
      <Header
        className="shadow-lg border-gray sticky lg:fixed lg:border-b lg:shadow-none"
        isGuidesPage
      />
      <div className="flex flex-wrap lg:layout-container lg:flex-nowrap">
        <GuideNav
          currentSlug={frontmatter.slug}
          currentTitle={frontmatter.title}
          currentCategory={frontmatter.category}
        />
        <main className="w-full flex-grow py-6 px-8 md:px-12 lg:pr-0">
          <HeaderSpacer className="hidden lg:block" />
          <div
            dangerouslySetInnerHTML={{ __html: html }}
            className="guide-container"
          />
          <GuideFooter previousGuide={previousGuide} nextGuide={nextGuide} />
        </main>
      </div>
    </>
  );
}

export const Head = ({ data }) => {
  const {
    markdownRemark: { frontmatter },
  } = data;
  return (
    <PageHead
      title={`Lasagna - ${frontmatter.title}`}
      description={frontmatter.description}
      relativePath={frontmatter.slug}
    />
  );
};

export const pageQuery = graphql`
  query ($slug: String!) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      html
      frontmatter {
        slug
        title
        category
      }
    }
  }
`;
