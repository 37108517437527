import React from "react";

export default function PageHead({
  title,
  description,
  relativePath,
}: {
  title: string;
  description: string;
  relativePath: string;
}) {
  return (
    <>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta property="og:title" content={title} />
      <meta property="og:type" content="website" />
      <meta property="og:url" content={`${LASAGNA_BASE_URL}${relativePath}`} />
      <meta property="og:image" content={OG_IMAGE_URL} />
      <meta property="og:description" content={description} />
      <meta property="twitter:card" content="summary_large_image" />
      <meta property="twitter:site" content={LASAGNA_TWITTER_HANDLE} />
      <meta property="twitter:title" content={title} />
      <meta property="twitter:description" content={description} />
      <meta property="twitter:image" content={OG_IMAGE_URL} />
      <link
        rel="apple-touch-icon"
        sizes="180x180"
        href="/apple-touch-icon.png"
      />
      <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32.png" />
      <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16.png" />
      <link rel="manifest" href="/site.webmanifest" />
      <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#fe8001" />
      <meta name="msapplication-TileColor" content="#000000" />
      <meta name="theme-color" content="#000000" />
    </>
  );
}

const LASAGNA_BASE_URL = "https://lasagna.xyz";
const OG_IMAGE_URL = `${LASAGNA_BASE_URL}/og-image.png`;
const LASAGNA_TWITTER_HANDLE = "@lasagnaxyz";
